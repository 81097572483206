import React from "react";
import lapin_1 from "./photos/IMG_8874.jpg";
import lapin_2 from "./photos/IMG_8857.jpg";
import Jar from "../Jar";

function Lapin() {
  return (
    <Jar
      title="Lapin aux Noisettes"
      desc="Quelques lapins, des noisettes et un chouilla de cochon pour ce pâté !"
      img={lapin_1}
      ingredients={[
        "Lapin entier",
        "Echine, poitrine et gras de cochon",
        "Noisettes",
        "Crème fleurette",
        "Oeufs",
        "Piment d'Espelette",
        "Armagnac",
        "Sel, poivre",
      ]}
      preservation={[
        "Ce pâté a été stérilisé pendant 3h, il peut donc se conserver pendant 6 mois",
        "Il est préférable de le conserver au réfrégirateur, il n'en sera que meilleur",
        "Préparé et stérilisé le 3 Avril 2022, en Normandie",
      ]}
      tasting={[
        "Tonton vous recommande de prévoir du bon pain de campagne, des cornichons et du cidre ou de la bière. Un peu de fromage pour accompagner et ce sera parfait !",
      ]}
      gallery={[lapin_2]}
    />
  );
}

export default Lapin;
