import React from "react";
import Jar from "../Jar";
import rillettes_1 from "./photos/IMG_7383.jpg";
import rillettes_2 from "./photos/IMG_8296.jpg";
import rillettes_3 from "./photos/IMG_8858.jpg";
import rillettes_4 from "./photos/IMG_8866.jpg";

function Rillettes() {
  return (
    <Jar
      title="Rillettes de Cochon"
      desc="Les Rillettes du Mans, un classique !"
      img={rillettes_1}
      ingredients={[
        "Echine et poitrine de cochon",
        "Piment d'Espelette",
        "Sel, poivre",
      ]}
      preservation={[
        "Ces rillettes ont été stérilisées pendant 1h30, elles peuvent se conserver pendant 1 mois au réfrégirateur",
        "Préparé et stérilisé le 3 Avril 2022, en Normandie",
      ]}
      tasting={[
        "Tonton vous recommande de prévoir du bon pain de campagne, des cornichons et surtout: de l'effervescent ! Champagne, proseco, bière, cidre Normand (ou Breton c'est OK).",
      ]}
      gallery={[rillettes_2, rillettes_3, rillettes_4]}
    />
  );
}

export default Rillettes;
