import React from "react";
import { useParams } from "react-router";
import "./QRCodes.css";
import Canard from "./qrcodes/CommandesMars2022/Canard";
import Cheesecake from "./qrcodes/CommandesMars2022/Cheesecake";
import Cochon from "./qrcodes/CommandesMars2022/Cochon";
import Lapin from "./qrcodes/CommandesMars2022/Lapin";
import Rillettes from "./qrcodes/CommandesMars2022/Rillettes";

function QRCodes() {
  const { id } = useParams();

  switch (id) {
    // Mars 2022: Cheesecake
    case "04fbc1f7-265e-46bb-8255-53698452b8d8":
      return <Cheesecake />;
    // Mars 2022: Canard aux Pistaches
    case "054ecccb-bda3-488d-8f04-2793f9b1dbb1":
      return <Canard />;
    // Mars 2022: Pâté de Cochon persillé
    case "aa587cb9-aa5b-4998-abd6-794a2d54c836":
      return <Cochon />;
    // Mars 2022: Pâté de Lapin aux noisettes
    case "699dfd1b-8f68-4ba5-a17d-151d69d0d517":
      return <Lapin />;
    // Mars 2022: Rillettes de Cochon
    case "ac5c4ec1-e737-4f55-8da1-1632b15dde2d":
      return <Rillettes />;
    default:
      return <div>QRCode not found!</div>;
  }
}

export default QRCodes;
