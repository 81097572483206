import React from "react";
import Jar from "../Jar";
import cochon_1 from "./photos/18835062-8aa7-4588-96ed-7f0f93da99a7.jpg";
import cochon_2 from "./photos/IMG_8814.jpg";
import cochon_3 from "./photos/IMG_8818.jpg";

function Cochon() {
  return (
    <Jar
      title="Le Cochon Persillé de Mamie"
      desc="C'est LA recette traditionnelle de Tonton, ça vient de la mamie !"
      img={cochon_1}
      ingredients={[
        "Echine, tête, langue et jarret de cochon",
        "Echalottes, ail, persil frisé",
        "Vin blanc",
        "Crème fleurette",
        "Oeufs",
        "Sel, poivre",
      ]}
      preservation={[
        "Ce pâté a été stérilisé pendant 3h, il peut donc se conserver pendant 6 mois",
        "Il est préférable de le conserver au réfrégirateur, il n'en sera que meilleur",
        "Préparé et stérilisé le 28 Mars 2022, en Normandie",
      ]}
      tasting={[
        "Tonton vous recommande de prévoir du bon pain de campagne, des cornichons et un bon verre de rouge. Et un petit monceau de cubes de Comté sur le côté: miam !",
      ]}
      gallery={[cochon_2, cochon_3]}
    />
  );
}

export default Cochon;
