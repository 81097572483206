import React from "react";
import "./App.css";
import logo from "./logo.png";
import { INSTAGRAM_URL } from "./shared";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          <a className="App-link" href={INSTAGRAM_URL}>
            <span role="img" aria-label="backhand-index-pointing-right">
              👉
            </span>{" "}
            Instagram{" "}
            <span role="img" aria-label="backhand-index-pointing-left">
              👈
            </span>
          </a>
        </p>
      </header>
    </div>
  );
}

export default App;
