import React from "react";
import canard_1 from "./photos/IMG_8820.jpg";
import canard_2 from "./photos/IMG_8794.jpg";
import canard_3 from "./photos/IMG_8804.jpg";
import canard_4 from "./photos/IMG_8808.jpg";
import Jar from "../Jar";

function Canard() {
  return (
    <Jar
      title="Pâté de Canard aux Pistaches"
      desc="Ça change du tout Cochon, et c'est rustique !"
      img={canard_1}
      ingredients={[
        "Magret de canard",
        "Sauté de canard",
        "Poitrine de cochon",
        "Pistaches",
        "Oeufs",
        "Sel, poivre",
      ]}
      preservation={[
        "Ce pâté a été stérilisé pendant 3h, il peut donc se conserver pendant 6 mois",
        "Il est préférable de le conserver au réfrégirateur, il n'en sera que meilleur",
        "Préparé et stérilisé le 28 Mars 2022, en Normandie",
      ]}
      tasting={[
        "Tonton vous recommande bien sûr de l'attaquer avec quelques cornichons, de bonnes tranches de pain de campagne frais, et bien sûr un bon verre de vin rouge",
      ]}
      gallery={[canard_2, canard_3, canard_4]}
    />
  );
}

export default Canard;
