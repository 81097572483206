import React from "react";
import cheesecake_1 from "./photos/IMG_8538.jpg";
import cheesecake_2 from "./photos/IMG_8601.jpg";
import cheesecake_3 from "./photos/IMG_8599.jpg";
import cheesecake_4 from "./photos/IMG_8600.jpg";
import Jar from "../Jar";

function Cheesecake() {
  return (
    <Jar
      title="Cheesecake Spéculos"
      desc="Ceci, un cheesecake ? L'histoire de ce dessert est assez longue: initialement préparé sous forme de gâteau pour les anniversaires, Tonton l'a adapté sous forme de verrine, plus facile à servir et à déguster !"
      img={cheesecake_1}
      ingredients={[
        "Biscuit spéculos",
        "Crème spéculos",
        "Cream cheese",
        "Chantilly",
        "Vanille",
        "Sucre",
      ]}
      preservation={[
        "Il se conserve au réfrégirateur, et peut y rester une semaine maximum",
        "Préparé et mis en bocal le 4 Avril 2022, à Paris",
      ]}
      tasting={[
        "Tonton vous recommande de le dégommer après un repas plutôt léger, car c'est costaud (mais si réconfortant !)",
      ]}
      gallery={[cheesecake_2, cheesecake_3, cheesecake_4]}
    />
  );
}

export default Cheesecake;
