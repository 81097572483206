import React from "react";
import Payment from "./Payment";

interface JarProps {
  title: string;
  desc: string;
  img: string;
  ingredients: string[];
  preservation: string[];
  tasting: string[];
  gallery: string[];
}

function Jar(props: JarProps) {
  return (
    <article>
      <header>
        <div className="desc">
          <h1>{props.title}</h1>
          <img src={props.img} alt="Vue principale" />
          <p>{props.desc}</p>

          <p>Ingrédients:</p>
          <ul>
            {props.ingredients.map((ingredient) => (
              <li>{ingredient}</li>
            ))}
          </ul>

          <p>Conservation:</p>
          <ul>
            {props.preservation.map((advice) => (
              <li>{advice}</li>
            ))}
          </ul>

          <p>Dégustation:</p>
          <ul>
            {props.tasting.map((advice) => (
              <li>{advice}</li>
            ))}
          </ul>
        </div>
      </header>
      <section id="gallery">
        {props.gallery.map((img) => (
          <img width={300} src={img} alt="illustration" />
        ))}
      </section>
    </article>
  );
}

export default Jar;
